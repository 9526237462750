export function InitializeEmailSignupPage() {
    var urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has('email')) {
        var emailTextbox = document.getElementById('AccountLoginBrief_EmailAddress') as HTMLInputElement;
        if (emailTextbox) {
            emailTextbox.value = urlParams.get('email');
        }
    }
}
